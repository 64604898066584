import React, { useState, useEffect } from 'react';
import './Gallery.css'; // Import CSS

const Gallery = () => {
  const [drawings, setDrawings] = useState([]); // Store drawings metadata
  const [error, setError] = useState(null);    // Store error messages
  const [modalData, setModalData] = useState(null); // Store data for the modal

  useEffect(() => {
    fetch('https://chaptude.com/list-drawings') // Use the production URL
      .then((response) => {
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        if (data.success) {
          setDrawings(data.drawings);
        } else {
          throw new Error(data.error || 'Failed to fetch drawings.');
        }
      })
      .catch((err) => {
        console.error('Error fetching drawings:', err.message);
        setError(err.message);
      });
  }, []);

  // Handle image click to open the modal
  const handleImageClick = (drawing) => {
    setModalData(drawing);
  };

  // Handle modal close
  const handleCloseModal = () => {
    setModalData(null);
  };

  // Count tags and their occurrences
  const tagCounts = drawings.reduce((acc, drawing) => {
    drawing.tags.forEach(tag => {
      acc[tag] = (acc[tag] || 0) + 1;
    });
    return acc;
  }, {});

  // Sort tags alphabetically
  const sortedTags = Object.entries(tagCounts).sort((a, b) => a[0].localeCompare(b[0]));

  if (error) {
    return <div className="gallery-container" style={{ color: 'red' }}>Error: {error}</div>;
  }

  return (
    <div className="gallery-container">
      {/* Green Box: Display Year */}
      <div style={{padding: '10px', color: 'white' }}>
        {drawings.length > 0 && new Date(drawings[0].draw_date).getFullYear()}
      </div>

      {/* Yellow Box: Display Tags and Occurrences */}
      <div style={{padding: '10px', overflowY: 'auto' }}>
        {sortedTags.map(([tag, count]) => (
          <div key={tag}>
            {tag} ({count})
          </div>
        ))}
      </div>

      {/* Gallery Section */}
      <h1 className="gallery-title">Gallery</h1>
      <div className="gallery-grid">
        {drawings.map(drawing => (
          <div className="gallery-item" key={drawing.id}>
            <img
              className="gallery-thumbnail"
              src={`https://chaptude.com/gallery/${drawing.file_name}`}
              alt={drawing.description || 'Drawing'}
              onClick={() => handleImageClick(drawing)}
            />
            <h3>{drawing.file_name}</h3>
          </div>
        ))}
      </div>

      {/* Modal for Expanded Image */}
      {modalData && (
        <div className="gallery-modal-overlay" onClick={handleCloseModal}>
          <div
            className="gallery-modal-content"
            onClick={(e) => e.stopPropagation()} // Prevent closing on inner click
          >
            <button className="gallery-modal-close" onClick={handleCloseModal}>
              &times;
            </button>
            <img
              className="gallery-modal-image"
              src={`https://chaptude.com/gallery/${modalData.file_name}`} // Full-sized image
              alt={modalData.description || 'Drawing'}
            />
            <h3 className="gallery-modal-title">{modalData.file_name}</h3>
            <p className="gallery-modal-description">{modalData.description}</p>
            <a
              className="gallery-modal-download"
              href={`https://chaptude.com/gallery/${modalData.file_name}`} // Link to download full-sized image
              download={modalData.file_name}
            >
              Download
            </a>
          </div>
        </div>
      )}
    </div>
  );
};

export default Gallery;
